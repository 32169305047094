import Layout from '../components/layout'
import './Support.scss'

const Support = (props) => {
    return (
      <Layout {...props} className="category">
        <div className='title'></div>
        <div style={{paddingTop: '10px'}}>
          <h2>Help & Support</h2>
          <p>More to go in here!</p>
          <p>For more information about XMPie, please visit <a href="http://www.xmpie.com" title="www.xmpie.com">www.xmpie.com</a>.</p>         

          <h2>Problems with using this site?</h2>
          <p>If you encounter any problems using this storefront, please contact the store admin team at: <a href="demo@xmpie.com" title="XMPie Demo Store Issue">demo@xmpie.com</a></p>
        </div>
      </Layout>
    )
}
  
export default Support