import React, { Component } from "react"
import './Footer.scss'
import { Link } from 'react-router-dom'
import { t } from '$themelocalization'
import { getVariableValue } from '$ustoreinternal/services/cssVariables'

/**
 * This component represents the footer in the store
 */
class Footer extends Component {
  render() {
    const footerLogo = getVariableValue('--POC-footer-logo', require(`$assets/images/xmpie-POC-theme-logo.png`), true)
    const heading1 = getVariableValue('--POC-footer-column1-header', t('footerHeader1'))
    const heading2 = getVariableValue('--POC-footer-column2-header', t('footerHeader2'))
    const textCol1row1 = getVariableValue('--POC-footer-column1-row1', t('footerCol1Row1'));
    const textCol1row2 = getVariableValue('--POC-footer-column1-row2', t('footerCol1Row2'));
    const textCol1row3 = getVariableValue('--POC-footer-column1-row3', t('footerCol1Row3'));
    const linkCol1row1 = getVariableValue('--POC-footer-column1-row1-link', '', false, true);
    const linkCol1row2 = getVariableValue('--POC-footer-column1-row2-link', '', false, true);
    const linkCol1row3 = getVariableValue('--POC-footer-column1-row3-link', '', false, true);
    const textCol2row1 = getVariableValue('--POC-footer-column2-row1', t('footerCol2Row1'));
    const textCol2row2 = getVariableValue('--POC-footer-column2-row2', t('footerCol2Row2'));
    const textCol2row3 = getVariableValue('--POC-footer-column2-row3', t('footerCol2Row3'));
    const linkCol2row1 = getVariableValue('--POC-footer-column2-row1-link', '', false, true);
    const linkCol2row2 = getVariableValue('--POC-footer-column2-row2-link', '', false, true);
    const linkCol2row3 = getVariableValue('--POC-footer-column2-row3-link', '', false, true);
    const textCol3row1 = getVariableValue('--POC-footer-column3-row1', t('footerCol3Row1'));
    const textCol3row2 = getVariableValue('--POC-footer-column3-row2', t('footerCol3Row2'));
    const textCol3row3 = getVariableValue('--POC-footer-column3-row3', t('footerCol3Row3'));
    const linkCol3row1 = getVariableValue('--POC-footer-column3-row1-link', '', false, true);
    const linkCol3row2 = getVariableValue('--POC-footer-column3-row2-link', '', false, true);
    const linkCol3row3 = getVariableValue('--POC-footer-column3-row3-link', 'https://www.xmpie.com/products/web-to-print/storeflow/', false, true);
    const logoLink = getVariableValue('--cust-footer-logo-link', '', false, true);

    let link11;
    if (linkCol1row1.length > 0 && linkCol1row1.substring(0,4).toLowerCase() === 'http') {
      link11 = <span className="footLink"><a id="footer-c1r1" href={linkCol1row1}>{textCol1row1}</a><br /></span>;
    } else if (linkCol1row1.length > 0) {
      link11 = <span className="footLink"><Link to={linkCol1row1} id="footer-c1r1">{textCol1row1}</Link><br /></span>;
    } else {
      link11 = <span className="footText">{textCol1row1}<br /></span>;
    }
    let link12;
    if (linkCol1row2.length > 0 && linkCol1row2.substring(0,4).toLowerCase() === 'http') {
      link12 = <span className="footLink"><a id="footer-c1r2" href={linkCol1row2}>{textCol1row2}</a><br /></span>;
    } else if (linkCol1row2.length > 0) {
      link12 = <span className="footLink"><Link to={linkCol1row2} id="footer-c1r1">{textCol1row2}</Link><br /></span>;
    } else {
      link12 = <span className="footText">{textCol1row2}<br /></span>;
    }
    let link13;
    if (linkCol1row3.length > 0 && linkCol1row3.substring(0,4).toLowerCase() === 'http') {
      link13 = <span className="footLink"><a id="footer-c1r3" href={linkCol1row3}>{textCol1row3}</a></span>;
    } else if (linkCol1row3.length > 0) {
      link13 = <span className="footLink"><Link to={linkCol1row3} id="footer-c1r3">{textCol1row3}</Link></span>;
    } else {
      link13 = <span className="footText">{textCol1row3}</span>;
    }
    let link21;
    if (linkCol2row1.length > 0 && linkCol2row1.substring(0,4).toLowerCase() === 'http') {
      link21 = <span className="footLink"><a id="footer-c2r1" href={linkCol2row1}>{textCol2row1}</a><br /></span>;
    } else if (linkCol2row1.length > 0) {
      link21 = <span className="footLink"><Link to={linkCol2row1} id="footer-c2r1">{textCol2row1}</Link><br /></span>;
    } else {
      link21 = <span className="footText">{textCol2row1}<br /></span>;
    }
    let link22;
    if (linkCol2row2.length > 0 && linkCol2row2.substring(0,4).toLowerCase() === 'http') {
      link22 = <span className="footLink"><a id="footer-c2r2" href={linkCol2row2}>{textCol2row2}</a><br /></span>;
    } else if (linkCol2row2.length > 0) {
      link22 = <span className="footLink"><Link to={linkCol2row2} id="footer-c2r2">{textCol2row2}</Link><br /></span>;
    } else {
      link22 = <span className="footText">{textCol2row2}<br /></span>;
    }
    let link23;
    if (linkCol2row3.length > 0 && linkCol2row3.substring(0,4).toLowerCase() === 'http') {
      link23 = <span className="footLink"><a id="footer-c2r3" href={linkCol2row3}>{textCol2row3}</a></span>;
    } else if (linkCol2row3.length > 0) {
      link23 = <span className="footLink"><Link to={linkCol2row3} id="footer-c2r3">{textCol2row3}</Link></span>;
    } else {
      link23 = <span className="footText">{textCol2row3}</span>;
    }
    let linklogo;
    if (logoLink.length > 0 && logoLink.substring(0,4).toLowerCase() === 'http') {
      linklogo = <a id="footer-logo-link" href={logoLink}><img src={footerLogo} alt="logo" className="footerLogo" /></a>;
    } else if (logoLink.length > 0) {
      linklogo = <Link to={logoLink} id="footer-logo-link"><img src={footerLogo} alt="logo" className="footerLogo" /></Link>;
    } else {
      linklogo = <img src={footerLogo} alt="logo" className="footerLogo" />;
    }
    let link31;
    if (linkCol3row1.length > 0 && linkCol3row1.substring(0,4).toLowerCase() === 'http'){
      link31 = <span className="companyLink"><a id="footer-c3r1" href={linkCol3row1}>{textCol3row1}</a><br /></span>;
    } else if (linkCol3row1.length > 0) {
      link31 = <span className="companyLink"><Link to={linkCol3row1} id="footer-c3r1">{textCol3row1}</Link><br /></span>;
    } else {
      link31 = <span className="companyLink">{textCol3row1}<br /></span>;
    }
    let link32;
    if (linkCol3row2.length > 0 && linkCol3row2.substring(0,4).toLowerCase() === 'http'){
      link32 = <span className="companyLink"><a id="footer-c3r2" href={linkCol3row2}>{textCol3row2}</a><br /></span>;
    } else if (linkCol3row2.length > 0) {
      link32 = <span className="companyLink"><Link to={linkCol3row2} id="footer-c3r2">{textCol3row2}</Link><br /></span>;
    } else {
      link32 = <span className="companyLink">{textCol3row2}<br /></span>;
    }
    let link33;
    if (linkCol3row3.length > 0 && linkCol3row3.substring(0,4).toLowerCase() === 'http'){
      link33 = <span className="companyLink"><a id="footer-c3r3" href={linkCol3row3}>{textCol3row3}</a></span>;
    } else if (linkCol3row3.length > 0) {
      link33 = <span className="companyLink"><Link to={linkCol3row3} id="footer-c3r3">{textCol3row3}</Link></span>;
    } else {
      link33 = <span className="companyLink">{textCol3row3}</span>;
    }

    return (
      <div className="footer">
        <div id="footer-column-one">
          <span className="footHeading" id="footer-colunm-one-header">{heading1}</span><br />
          {link11}
          {link12}
          {link13}
        </div>
        <div className="footer-column" id="footer-column-two">
        <span className="footHeading" id="footer-colunm-two-header">{heading2}</span><br />
          {link21}
          {link22}
          {link23}
        </div>
        <div className="footer-column"  id="footer-column-three">
        <div className="footer-logo-container">
          {linklogo}
        </div><br />
          {link31}
          {link32}
          {link33}
        </div>
      </div>
    )
  }
}
export default Footer
